<template>
  <div class="home-wrap">
    <div class="d-flex align-center justify-center flex-column height-full main-content">
      <v-card class="py-8" width="600" @click="onClickAttach">
        <v-card-text class="text-center primary-color f-s-24 f-w-700">
          <v-icon size="45" color="var(--theme_primary)">mdi-attachment</v-icon>
          Link Existing Loan
        </v-card-text>
      </v-card>
      <div v-if="displayCarousel" class="mt-15 width-100p">
        <div class="text-center mb-2">
          <span class="primary-color">{{ layouts.data.carouselIndex + 1 }}</span> / {{ cardItems.length }}
        </div>
        <v-carousel
          :height="carouselHeight"
          hide-delimiters
          cycle
          v-model="layouts.data.carouselIndex"
        >
          <v-carousel-item v-for="(item, index) in cardItems" :key="index">
            <div class="d-flex justify-center align-center">
              <component :is="cardComponent" :loan-info="item"></component>
            </div>
          </v-carousel-item>
        </v-carousel>
      </div>
      <v-alert
        v-else
        text
        prominent
        variant="outlined"
        type="warning"
        border="left"
        width="600"
        class="mt-15"
      >
        No linked loan.<br/>
        Please click above button to link a loan.
      </v-alert>
    </div>

    <v-dialog persistent v-model="layouts.dialogs.attachLoanDialog" max-width="600">
      <v-card>
        <v-card-title>
          <span class="headline p-10 m-b-15">Link Existing Loan</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="layouts.dialogs.attachLoanDialog = false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="layouts.data.loanNo"
            v-mask="'XXX-XXX-XXXX'"
            label="Enter Loan #"
          ></v-text-field>

          <v-text-field
            v-model="layouts.data.bankAccount"
            label="Enter Bank Account #"
          ></v-text-field>
          <v-alert
            dense
            text
            border="left"
            color="var(--theme_primary)"
            class="f-s-12"
          >
            <span class="text-red">*</span> You can find the needed information from the loan agreement of your existing loan.
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <web-btn
            class="mr-5"
            :height="50"
            width="40%"
            @click="attachLoan"
          >
            Link
          </web-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import WebBtn from '@/components/base/WebBtn'
import LoanProgressCard from '@/components/home/LoanProgressCard'
import loanSummaryCard from '@/components/home/LoanSummaryCard'
import { LoanApi } from '@/api'
import { DataType, Tool } from '@/assets/js/util'
import _ from 'lodash'

export default {
  name: 'Home',
  components: {
    WebBtn,
    LoanProgressCard,
    loanSummaryCard
  },
  data () {
    return {
      design: {
        activeItem: null
      },
      assist: {
        tool: new Tool()
      },
      cardDisplayControl: 'Disbursement',
      layouts: {
        data: {
          fullName: '',
          loanNo: '',
          bankAccount: '',
          account: {},
          progressCardArray: [],
          summaryCardArray: [],
          carouselIndex: null
        },
        settings: {
          navigation: [
            { title: 'Disbursement', icon: 'fa-hand-holding-usd' },
            { title: 'Repayment', icon: 'fa-envelope-open-dollar' }
          ]
        },
        dialogs: {
          attachLoanDialog: false
        }
      }
    }
  },
  computed: {
    carouselHeight () {
      return this.cardDisplayControl === 'Disbursement' ? 260 : 300
    },
    cardItems () {
      return this.cardDisplayControl === 'Disbursement'
        ? this.layouts.data.progressCardArray
        : this.layouts.data.summaryCardArray
    },
    displayCarousel () {
      if (this.cardDisplayControl === 'Disbursement') {
        return this.layouts.data.progressCardArray.length !== 0
      } else if (this.cardDisplayControl === 'Repayment') {
        return this.layouts.data.summaryCardArray.length !== 0
      }
      return false
    },
    cardComponent () {
      return this.cardDisplayControl === 'Disbursement' ? 'loan-progress-card' : 'loan-summary-card'
    }
  },
  watch: {
    'cardDisplayControl' () {
      this.getLoanCards()
    },
    'layouts.dialogs.attachLoanDialog' (val) {
      if (!val) {
        this.layouts.data.loanNo = ''
        this.layouts.data.bankAccount = ''
      }
    }
  },
  mounted () {
    this.getDisbursementLoan()
    this.getRepaymentLoan()
  },
  methods: {
    onClickAttach () {
      this.layouts.dialogs.attachLoanDialog = true
    },
    attachLoan () {
      const request = {
        loanNo: this.layouts.data.loanNo,
        accountNo: this.layouts.data.bankAccount,
        portfolioId: this.$store.getters.getPortfolioId
      }
      LoanApi.attachLoan(request, () => {
        this.layouts.dialogs.attachLoanDialog = false
        this.getDisbursementLoan()
        this.getRepaymentLoan()
      }, (failure) => {
        this.$store.commit('setPopupInformation', {
          message: failure.message
        })
      })
    },
    getLoanCards: _.throttle(
      function () {
        this.getDisbursementLoan()
        this.getRepaymentLoan()
      }, 5000, { trailing: false }),
    getDisbursementLoan () {
      LoanApi.listDisbursementLoan((res) => {
        if (this.assist.tool.isNotEmpty(res.attachLoanList)) {
          const Originating = DataType.LoanStatusEnum.ORIGINATING.value
          const Approved = DataType.LoanStatusEnum.APPROVED.value
          const Pending = DataType.LoanStatusEnum.PENDING.value
          const Disbursed = DataType.LoanStatusEnum.DISBURSED.value
          const availableStatus = [Originating, Approved, Pending, Disbursed]
          this.layouts.data.progressCardArray = res.attachLoanList.filter(i => availableStatus.includes(i.loanStatus))
        }
      })
    },
    getRepaymentLoan () {
      LoanApi.listRepaymentLoan((res) => {
        if (this.assist.tool.isNotEmpty(res.attachLoanList)) this.layouts.data.summaryCardArray = res.attachLoanList
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.primary-color
  color: var(--theme_primary) !important

.home-wrap
  height: 100%
  width: 100%
  display: flex
  justify-content: center
  align-items: center

.left-panel
  width: 300px
  height: 600px
  max-width: 1000px
  max-height: 600px
  display: flex
  flex-direction: column
  box-shadow: 12px 6px 24px 24px rgba(38, 46, 61, 0.03), 6px 4px 12px 0px rgba(29, 33, 38, 0.05)

.main-content
  width: 800px
  height: fit-content

.scroll
  height: 100%
  width: 100%
  padding: 0 5px
</style>
