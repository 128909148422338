<template>
  <v-card
    width="600" min-height="230">
    <v-card-title>
      <v-img
        :src="layouts.data.portfolio.iconUrl"
        max-height="40"
        max-width="40"
      />
      <span class="loan-no pl-3">{{ layouts.data.loanNo }}</span>
      <v-spacer></v-spacer>
      <span class="loan-status-dot mr-1"></span>
      <span class="loan-status">In Progress</span>
    </v-card-title>
    <v-card-text class="field-container">
      <div>
        <div class="field-label">Requested Amount</div>
        <div class="field-value">{{ assist.currency.format(layouts.data.requestAmount) }}</div>
      </div>
    </v-card-text>
    <v-card-text class="progress-section">
      <div class="loan-progress-container">
        <v-progress-linear
          buffer-value="100"
          :color="layouts.data.linearColor.linearOne"
          value="100">
        </v-progress-linear>
        <v-progress-linear
          buffer-value="100"
          :color="layouts.data.linearColor.linearTwo"
          value="100">
        </v-progress-linear>
        <v-progress-linear
          buffer-value="100"
          :color="layouts.data.linearColor.linearThree"
          value="100">
        </v-progress-linear>
      </div>
      <div class="step-container">
        <template v-for="(item, index) in layouts.settings.progressArray">
          <v-card
            :key="index"
            flat
            color="transparent"
            class="step"
          >
            <v-card-text>
              <v-sheet class="step-icon">
                <v-avatar size="14" color="primary" v-if="item.status === 'finished'">
                  <v-icon dark size="10">fa-check</v-icon>
                </v-avatar>
                <v-avatar size="14" color="secondary" v-else-if="item.status === 'in progress'">
                  <v-icon dark size="10" style="animation-duration: 5s;">fa-hourglass-start fa-spin</v-icon>
                </v-avatar>
                <v-avatar size="14" color="#989898" v-else>
                  <v-icon dark size="10">fa-ellipsis-h</v-icon>
                </v-avatar>
              </v-sheet>
              <div
                class="step-name"
                :style="item.status === 'in progress' ? 'color: var(--theme_secondary);' : item.status === 'unfinished' ? 'color: #989898;' : ''"
              >
                {{ item.text }}
              </div>
            </v-card-text>
          </v-card>
        </template>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { Currency, Tool, DataType } from '@/assets/js/util'
export default {
  name: 'LoanProgressCard',
  props: {
    loanInfo: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    assist: {
      currency: new Currency(),
      tool: new Tool()
    },
    layouts: {
      data: {
        loanNo: '',
        requestAmount: null,
        linearColor: {
          linearOne: '#9A9A9A',
          linearTwo: '#9A9A9A',
          linearThree: '#9A9A9A'
        },
        portfolio: {}
      },
      settings: {
        progressArray: [
          { text: 'Application Submitted', value: 1, status: 'in progress' },
          { text: 'Application Approved', value: 3, status: 'unfinished' },
          { text: 'Funding In Progress', value: 4, status: 'unfinished' },
          { text: 'Funding Completed', value: 5, status: 'unfinished' }
        ]
      }
    }
  }),
  watch: {
    'store.getters.getPortfolioJson': {
      handler (val) {
        if (this.assist.tool.isNotEmpty(val)) {
          this.layouts.data.portfolio = val
        }
      },
      immediate: true,
      deep: true
    },
    loanInfo: {
      handler (val) {
        if (this.assist.tool.isNotEmpty(val)) {
          this.layouts.data.loanNo = val.loanNo
          this.layouts.data.requestAmount = val.totalPrincipal
          this.judgeLoanStatus()
        }
      }
    }
  },
  mounted () {
    this.layouts.data.loanNo = this.loanInfo.loanNo
    this.layouts.data.requestAmount = this.loanInfo.totalPrincipal
    if (this.assist.tool.isNotEmpty(this.loanInfo)) this.judgeLoanStatus()
  },
  methods: {
    judgeLoanStatus () {
      const Originating = DataType.LoanStatusEnum.ORIGINATING.value
      const Approved = DataType.LoanStatusEnum.APPROVED.value
      const Pending = DataType.LoanStatusEnum.PENDING.value
      const Disbursed = DataType.LoanStatusEnum.DISBURSED.value
      const availableStatus = [Originating, Approved, Pending, Disbursed]
      if (availableStatus.indexOf(this.loanInfo.loanStatus) === -1) {
        return
      }

      if (this.loanInfo.loanStatus === Approved || this.loanInfo.loanStatus === Pending) {
        this.layouts.settings.progressArray[0].status = 'finished'
        this.layouts.settings.progressArray[1].status = 'finished'
        this.layouts.settings.progressArray[2].status = 'in progress'
        this.layouts.settings.progressArray[3].status = 'unfinished'
        this.layouts.data.linearColor.linearOne = 'var(--theme_primary)'
        this.layouts.data.linearColor.linearTwo = 'var(--theme_secondary)'
        this.layouts.data.linearColor.linearThree = '#989898'
        return
      } else if (this.loanInfo.loanStatus === Disbursed) {
        this.layouts.settings.progressArray[0].status = 'finished'
        this.layouts.settings.progressArray[1].status = 'finished'
        this.layouts.settings.progressArray[2].status = 'finished'
        this.layouts.settings.progressArray[3].status = 'in progress'
        this.layouts.data.linearColor.linearOne = 'var(--theme_primary)'
        this.layouts.data.linearColor.linearTwo = 'var(--theme_primary)'
        this.layouts.data.linearColor.linearThree = 'var(--theme_secondary)'
        return
      }

      switch (this.loanInfo.subStatusList[0]) {
        case DataType.LoanSubStatusEnum.CUSTOMER_REVIEW.value:
        case DataType.LoanSubStatusEnum.NON_VOICE_AGENT_REVIEW.value:
        case DataType.LoanSubStatusEnum.AGENT_REVIEW.value:
          this.layouts.settings.progressArray[0].status = 'in progress'
          this.layouts.settings.progressArray[1].status = 'unfinished'
          this.layouts.settings.progressArray[2].status = 'unfinished'
          this.layouts.settings.progressArray[3].status = 'unfinished'
          this.layouts.data.linearColor.linearOne = '#989898'
          this.layouts.data.linearColor.linearTwo = '#989898'
          this.layouts.data.linearColor.linearThree = '#989898'
          break
        case DataType.LoanSubStatusEnum.UNDERWRITER_REVIEW.value:
        case DataType.LoanSubStatusEnum.AUTO_VALIDATION.value:
        case DataType.LoanSubStatusEnum.TRIBE_REVIEW.value:
          this.layouts.settings.progressArray[0].status = 'finished'
          this.layouts.settings.progressArray[1].status = 'in progress'
          this.layouts.settings.progressArray[2].status = 'unfinished'
          this.layouts.settings.progressArray[3].status = 'unfinished'
          this.layouts.data.linearColor.linearOne = 'var(--theme_secondary)'
          this.layouts.data.linearColor.linearTwo = '#989898'
          this.layouts.data.linearColor.linearThree = '#989898'
          break
        default:
          break
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.loan-status
  font-weight: 400
.loan-status-dot
  width: 12px
  height: 12px
  border-radius: 20%
  background: #33dc3f
.field-container
  display: flex

  & .field-label
    color: rgba(2, 2, 2, .4)
    font-size: 12px
    line-height: 1

  & .field-value
    color: rgba(2, 2, 2, .8)
    font-size: 30px
    font-weight: 700
    line-height: 1.5

  & .card-action
    background-color: var(--theme_primary)
    color: var(--theme_secondary)
    width: 175px
    height: 40px !important
    font-size: 18px !important
    font-weight: 600
    margin-top: 15px

.progress-section
  position: relative

.loan-progress-container
  display: flex
  justify-content: space-between
  position: absolute
  top: 20px
  width: calc(100% - 32px)
  padding: 0 44px

.step-container
  display: flex
  justify-content: space-between
  position: absolute
  top: 0
  width: calc(100% - 32px)
  padding: 0 16px

  & .step
    text-align: center
    min-width: 50px

    & .step-icon
      display: flex
      justify-content: center
      align-items: flex-start
      height: 30px
      background-color: transparent

      & .v-icon
        font-size: 12px

    & .step-name
      display: flex
      position: absolute
      left: 50%
      width: 60px
      -webkit-transform: translate(-50%, -50%)
      -moz-transform: translate(-50%, -50%)
      font-size: 10px
      line-height: 1
</style>
