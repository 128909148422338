<template>
  <v-card min-width="600" class="repayment-card" @click="onClickCard">
    <v-card-title>
      <img
        :src="layouts.data.portfolio.iconUrl"
        class="width-40 height-40 m-r-16"
      />
      <span class="loan-no pl-3">{{ layouts.data.repayment.loanNo }}</span>
      <v-spacer></v-spacer>
      <span class="loan-status-dot mr-1"></span>
      <span class="loan-status">Processing</span>
    </v-card-title>
    <v-layout class="repayment-card-middle">
      <v-card-actions class="principal-section px-0">
        <v-card-text class="px-0">
          <div class="field-label ">Total Principal</div>
          <div class="field-value">
            {{ assist.currency.format(layouts.data.repayment.totalPrincipal) }}
          </div>
        </v-card-text>
        <v-card-text class="px-0 text-right">
          <div class="field-label">Unpaid Principal</div>
          <div class="field-value">
            {{ assist.currency.format(layouts.data.repayment.unpaidPrincipal) }}
          </div>
        </v-card-text>
      </v-card-actions>
      <v-spacer></v-spacer>
    </v-layout>
    <v-card-text class="progress-section">
      <div class="progress-container">
        <v-progress-linear
          buffer-value="100"
          :color="layouts.data.repaymentLinearColor.linearOne"
          striped
          value="100"
        >
        </v-progress-linear>
        <v-progress-linear
          buffer-value="100"
          :color="layouts.data.repaymentLinearColor.linearTwo"
          striped
          value="100"
        >
        </v-progress-linear>
        <v-progress-linear
          buffer-value="100"
          :color="layouts.data.repaymentLinearColor.linearThree"
          striped
          value="100"
        >
        </v-progress-linear>
      </div>
      <div class="installment-container">
        <template
          v-for="(item, index) in layouts.settings.repaymentProgressArray"
        >
          <v-card :key="index" flat color="transparent" class="installment">
            <v-card-text v-if="item.isShow">
              <div
                class="installment-summary"
                :style="
                  item.status === 'in progress'
                    ? 'color: var(--theme_secondary);'
                    : item.status === 'unfinished'
                    ? 'color: #989898; text-align: right; margin-left: -24px'
                    : item.status === 'finished'
                    ? 'text-align: left; margin-left: 24px'
                    : ''
                "
              >
                <div class="installment-date">{{ item.date }}</div>
                <div class="installment-amount">
                  {{ assist.currency.format(item.repayment) }}
                </div>
              </div>
              <div class="installment-icon">
                <v-icon v-if="item.status === 'finished'" color="primary"
                  >fa-circle
                </v-icon>
                <v-icon
                  v-else-if="item.status === 'in progress'"
                  color="secondary"
                  >fa-circle
                </v-icon>
                <v-icon v-else color="#989898">fa-circle </v-icon>
              </div>
              <div
                class="installment-no"
                :style="
                  item.status === 'in progress'
                    ? 'color: var(--theme_secondary);'
                    : item.status === 'unfinished'
                    ? 'color: #989898; margin-left: -7px'
                    : item.status === 'finished'
                    ? 'margin-left: 2px'
                    : ''
                "
              >
                #{{ item.period }}
              </div>
            </v-card-text>
          </v-card>
        </template>
      </div>
    </v-card-text>
    <v-card-actions class="card-footer pa-0">
      <v-card-text>
        <div class="card-footer-text">
          First Due Date: {{ layouts.data.repayment.firstDebitDate }}
        </div>
      </v-card-text>
      <v-spacer></v-spacer>
      <v-card-text>
        <div class="card-footer-text text-right">
          Maturity Date: {{ layouts.data.repayment.lastDebitDate }}
        </div>
      </v-card-text>
    </v-card-actions>
  </v-card>
</template>

<script>
import { Currency, DataType, Tool } from '@/assets/js/util'
export default {
  name: 'LoanSummaryCard',
  props: {
    loanInfo: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    assist: {
      tool: new Tool(),
      currency: new Currency(),
      dataType: DataType
    },
    layouts: {
      data: {
        repayment: {
          totalPrincipal: null,
          firstDebitDate: '',
          lastDebitDate: '',
          loanNo: '',
          loanId: '',
          unpaidPrincipal: '',
          nextDebitCount: null,
          nextAmount: null,
          nextDebitDate: null,
          debitCount: null,
          installments: []
        },
        repaymentLinearColor: {
          linearOne: 'primary',
          linearTwo: 'secondary',
          linearThree: 'grey'
        },
        portfolio: {}
      },
      settings: {
        repaymentProgressArray: [
          {
            period: 1,
            date: '',
            repayment: '',
            isShow: true,
            status: 'finished'
          },
          {
            period: null,
            date: '',
            repayment: '',
            isShow: false,
            status: 'in progress'
          },
          {
            period: null,
            date: '',
            repayment: '',
            isShow: true,
            status: 'in progress'
          },
          {
            period: null,
            date: '',
            repayment: '',
            isShow: true,
            status: 'unfinished'
          }
        ]
      }
    }
  }),
  watch: {
    'store.getters.getPortfolioJson': {
      handler (val) {
        if (this.assist.tool.isNotEmpty(val)) {
          this.layouts.data.portfolio = val
        }
      },
      immediate: true,
      deep: true
    },
    loanInfo: {
      handler (val) {
        if (this.assist.tool.isNotEmpty(val)) {
          this.layouts.data.repayment = val
          this.initRepaymentProgressArray()
        }
      }
    }
  },
  mounted () {
    this.layouts.data.repayment = this.loanInfo
    this.initRepaymentProgressArray()
  },
  methods: {
    onClickCard () {
      this.$store.commit('setRepaymentLoanInfo', this.layouts.data.repayment)
      this.$router.push('/repayment/loan-details')
    },
    initRepaymentProgressArray () {
      this.layouts.data.repayment.installments = this.loanInfo.installments.filter((i) => i.transactionType === Number(DataType.TransactionTypeEnum.DEBIT.value))
      this.layouts.data.repayment.firstDebitDate = this.loanInfo.installments[0]?.transactionDate
      this.layouts.data.repayment.firstDebitAmount = this.loanInfo.installments[0]?.principal
      this.layouts.data.repayment.lastDebitDate = this.loanInfo.installments[this.loanInfo.installments.length - 1]?.transactionDate
      this.layouts.data.repayment.lastDebitAmount = this.loanInfo.installments[this.loanInfo.installments.length - 1]?.principal
      const data = this.layouts.data.repayment

      if (Number.parseInt(data.nextDebitCount) === 1) {
        this.layouts.settings.repaymentProgressArray = [
          {
            period: 1,
            date: data.firstDebitDate,
            repayment: data.firstDebitAmount,
            isShow: true,
            status: 'in progress'
          },
          {
            period: data.debitCount,
            date: data.lastDebitDate,
            repayment: data.lastDebitAmount,
            isShow: true,
            status: 'unfinished'
          }
        ]
        this.layouts.data.repaymentLinearColor.linearOne = '#9A9A9A'
        this.layouts.data.repaymentLinearColor.linearTwo = '#9A9A9A'
        this.layouts.data.repaymentLinearColor.linearThree = '#9A9A9A'
      } else if (Number.parseInt(data.nextDebitCount) === data.debitCount) {
        this.layouts.settings.repaymentProgressArray = [
          {
            period: 1,
            date: data.firstDebitDate,
            repayment: data.firstDebitAmount,
            isShow: true,
            status: 'finished'
          },
          {
            period: data.debitCount,
            date: data.lastDebitDate,
            repayment: data.lastDebitAmount,
            isShow: true,
            status: 'in progress'
          }
        ]
        this.layouts.data.repaymentLinearColor.linearOne = 'primary'
        this.layouts.data.repaymentLinearColor.linearTwo = 'primary'
        this.layouts.data.repaymentLinearColor.linearThree = 'secondary'
      } else if (Number.parseInt(data.nextDebitCount) === 0 || this.assist.tool.isEmpty(data.nextDebitCount)) {
        this.layouts.settings.repaymentProgressArray = [
          {
            period: 1,
            date: data.firstDebitDate,
            repayment: data.firstDebitAmount,
            isShow: true,
            status: 'finished'
          },
          {
            period: data.debitCount,
            date: data.lastDebitDate,
            repayment: data.lastDebitAmount,
            isShow: true,
            status: 'unfinished'
          }
        ]
        this.layouts.data.repaymentLinearColor.linearOne = 'primary'
        this.layouts.data.repaymentLinearColor.linearTwo = 'primary'
        this.layouts.data.repaymentLinearColor.linearThree = 'primary'
      } else {
        this.layouts.settings.repaymentProgressArray = [
          {
            period: 1,
            date: data.firstDebitDate,
            repayment: data.firstDebitAmount,
            isShow: true,
            status: 'finished'
          },
          {
            isShow: false
          },
          {
            period: data.nextDebitCount,
            date: data.nextDebitDate,
            repayment: data.nextAmount,
            isShow: true,
            status: 'in progress'
          },
          {
            period: data.debitCount,
            date: data.lastDebitDate,
            repayment: data.lastDebitAmount,
            isShow: true,
            status: 'unfinished'
          }
        ]
        this.layouts.data.repaymentLinearColor.linearOne = 'primary'
        this.layouts.data.repaymentLinearColor.linearTwo = 'secondary'
        this.layouts.data.repaymentLinearColor.linearThree = '#9A9A9A'
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.repayment-card
  width: 600px
  & .loan-status-dot
    width: 12px
    height: 12px
    border-radius: 20%
    background: #33dc3f
  & .loan-status
    font-weight: 700
  &-middle
    width: 82%
    margin-left: 9%

  & .principal-section
    width: 100%

    & .field-label
      color: #9A9A9A
      font-size: 12px

    & .field-value
      color: var(--theme_secondary)
      font-size: 24px
      margin-top: 4px

  & .btn-section
    width: 60%
    //justify-content: space-around
    padding: 0

    & .repayment-btn
      background-color: var(--theme_primary)
      color: var(--theme_secondary)
      width: 100%
      height: 40px !important
      font-size: 18px !important
      font-weight: 600

  & .progress-section
    position: relative

    & .progress-container
      display: flex
      justify-content: center
      padding: 0 46px
      margin-top: 26px

    & .installment-container
      position: absolute
      top: 8px
      left: 38px
      display: flex
      justify-content: space-between
      width: calc(100% - 80px)

      & .installment
        text-align: center
        min-width: 50px

        & .installment-summary
          position: absolute
          left: 50%
          -webkit-transform: translate(-50%, -50%)
          -moz-transform: translate(-50%, -50%)
          font-size: 10px
          line-height: 1
          white-space: nowrap

        & .installment-icon
          display: flex
          justify-content: center
          align-items: center
          height: 40px

          & .v-icon
            font-size: 12px

        & .installment-no
          display: flex
          position: absolute
          left: 50%
          -webkit-transform: translate(-50%, -50%)
          -moz-transform: translate(-50%, -50%)
          font-size: 10px
          line-height: 1
.card-footer
  width: 88%
  margin-left: 6%

  &-text
    color: #9A9A9A !important
</style>
