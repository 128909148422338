var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"repayment-card",attrs:{"min-width":"600"},on:{"click":_vm.onClickCard}},[_c('v-card-title',[_c('img',{staticClass:"width-40 height-40 m-r-16",attrs:{"src":_vm.layouts.data.portfolio.iconUrl}}),_c('span',{staticClass:"loan-no pl-3"},[_vm._v(_vm._s(_vm.layouts.data.repayment.loanNo))]),_c('v-spacer'),_c('span',{staticClass:"loan-status-dot mr-1"}),_c('span',{staticClass:"loan-status"},[_vm._v("Processing")])],1),_c('v-layout',{staticClass:"repayment-card-middle"},[_c('v-card-actions',{staticClass:"principal-section px-0"},[_c('v-card-text',{staticClass:"px-0"},[_c('div',{staticClass:"field-label"},[_vm._v("Total Principal")]),_c('div',{staticClass:"field-value"},[_vm._v(" "+_vm._s(_vm.assist.currency.format(_vm.layouts.data.repayment.totalPrincipal))+" ")])]),_c('v-card-text',{staticClass:"px-0 text-right"},[_c('div',{staticClass:"field-label"},[_vm._v("Unpaid Principal")]),_c('div',{staticClass:"field-value"},[_vm._v(" "+_vm._s(_vm.assist.currency.format(_vm.layouts.data.repayment.unpaidPrincipal))+" ")])])],1),_c('v-spacer')],1),_c('v-card-text',{staticClass:"progress-section"},[_c('div',{staticClass:"progress-container"},[_c('v-progress-linear',{attrs:{"buffer-value":"100","color":_vm.layouts.data.repaymentLinearColor.linearOne,"striped":"","value":"100"}}),_c('v-progress-linear',{attrs:{"buffer-value":"100","color":_vm.layouts.data.repaymentLinearColor.linearTwo,"striped":"","value":"100"}}),_c('v-progress-linear',{attrs:{"buffer-value":"100","color":_vm.layouts.data.repaymentLinearColor.linearThree,"striped":"","value":"100"}})],1),_c('div',{staticClass:"installment-container"},[_vm._l((_vm.layouts.settings.repaymentProgressArray),function(item,index){return [_c('v-card',{key:index,staticClass:"installment",attrs:{"flat":"","color":"transparent"}},[(item.isShow)?_c('v-card-text',[_c('div',{staticClass:"installment-summary",style:(item.status === 'in progress'
                  ? 'color: var(--theme_secondary);'
                  : item.status === 'unfinished'
                  ? 'color: #989898; text-align: right; margin-left: -24px'
                  : item.status === 'finished'
                  ? 'text-align: left; margin-left: 24px'
                  : '')},[_c('div',{staticClass:"installment-date"},[_vm._v(_vm._s(item.date))]),_c('div',{staticClass:"installment-amount"},[_vm._v(" "+_vm._s(_vm.assist.currency.format(item.repayment))+" ")])]),_c('div',{staticClass:"installment-icon"},[(item.status === 'finished')?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("fa-circle ")]):(item.status === 'in progress')?_c('v-icon',{attrs:{"color":"secondary"}},[_vm._v("fa-circle ")]):_c('v-icon',{attrs:{"color":"#989898"}},[_vm._v("fa-circle ")])],1),_c('div',{staticClass:"installment-no",style:(item.status === 'in progress'
                  ? 'color: var(--theme_secondary);'
                  : item.status === 'unfinished'
                  ? 'color: #989898; margin-left: -7px'
                  : item.status === 'finished'
                  ? 'margin-left: 2px'
                  : '')},[_vm._v(" #"+_vm._s(item.period)+" ")])]):_vm._e()],1)]})],2)]),_c('v-card-actions',{staticClass:"card-footer pa-0"},[_c('v-card-text',[_c('div',{staticClass:"card-footer-text"},[_vm._v(" First Due Date: "+_vm._s(_vm.layouts.data.repayment.firstDebitDate)+" ")])]),_c('v-spacer'),_c('v-card-text',[_c('div',{staticClass:"card-footer-text text-right"},[_vm._v(" Maturity Date: "+_vm._s(_vm.layouts.data.repayment.lastDebitDate)+" ")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }